<template>
    <div class="card-statistics" >
      <div v-if="ApplicationData.dadosGraficoRespostaNiveisDepressao.length > 0">
        <b-overlay :show="!loadingShow" rounded="lg" opacity="0.6">

          <b-card-body class="statistics-body mb-n1 mt-n2">
            <b-row>
              <b-col
                v-for="(item, id) in statisticsItems"
                :key="id"
                xl="3"
                lg="6"
                md="12"
                class="pb-1"
                :class="item.customClass"
              >
                <b-col
                  xl="12"
                  lg="12"
                  class="titulo_espacamento"
                  :class="item.customTitulo"
                >
                  <strong class="text-muted  titulo_tamanho_responsivo">{{
                    item.titulo
                  }}</strong>
                </b-col>
                <b-col
                  xl="12"
                  lg="12"
                  class="centralizar_icone_porcentagem"
                  :class="item.customIcone"
                >
                  <span>
                    <img
                      :src="item.icone"
                      :alt="item.altIcone"
                      class="img-fluid"

                    />
                  </span>
                  <span class="porcentagemEstatisticas">
                    {{ item.porcentagem }}
                  </span>
                </b-col>
              </b-col>
            </b-row>
          </b-card-body>
        </b-overlay>
      </div>
      <div
          v-else
          class="height-custom d-flex align-items-center justify-content-center"
        >
          <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
      </div>

    </div>
  </template>

  <script>
  import {
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BOverlay,
    BCardTitle,
  } from "bootstrap-vue";
  import IconeCubo from "@/assets/custom-icons/cubo.svg";
  import IconeOms from "@/assets/custom-icons/oms.svg";
  import IconeBrasil from "@/assets/custom-icons/brasil.svg";
  import IconeS from "@/assets/custom-icons/iconeS.svg";
  import CardSemDados from "./CardSemDados.vue";

  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BCardHeader,
      BCardText,
      BCardBody,
      BMedia,
      BAvatar,
      BMediaAside,
      BMediaBody,
      BOverlay,
      BCardTitle,
      CardSemDados
    },
    setup() {
      return {
        IconeCubo,
        IconeOms,
        IconeBrasil,
        IconeS,
      };
    },
    props: {
      ApplicationData: {
        type: Object,
        default() {
          return {};
        },
        required: true,
      },
    },
    data() {
      return {
        loadingShow: false,
        dadosParaOComponenteFuncionar: [],
        validarCardSemDados: {
          verificadorVazio: true,
          tipagemErro: 0,
        },
        statisticsItems: [
          {
            icone: IconeCubo,
            altIcone: "minha-empresa",
            porcentagem: 0,
            customClass: "bg-light rounded",
            customTitulo: "tituloMinhaEmpresa",
            customIcone: "iconeMinhaEmpresa",
            titulo: "NESTA APLICAÇÃO",
          },
          {
            icone: IconeOms,
            altIcone: "oms",
            porcentagem: "5,8%",
            customClass: "",
            titulo: "ORGANIZAÇÃO MUNDIAL DA SAÚDE",
          },
          {
            icone: IconeBrasil,
            altIcone: "brasil",
            porcentagem: "10,2%",
            customClass: "",
            titulo: "PESQUISA DE SAÚDE BRASIL",
          },
          {
            icone: IconeS,
            altIcone: "sul",
            porcentagem: "15,2%",
            customClass: "",
            titulo: "PESQUISA DE SAÚDE REGIÃO SUL",
          },
        ],
      };
    },
    beforeMount() {
      this.buscaDadosCardEstatisticasDepressao();
    },
    methods: {
      buscaDadosCardEstatisticasDepressao() {
        this.loadingShow = false;

        if(this.ApplicationData.dadosGraficoRespostaNiveisDepressao.length > 0){
          this.dadosParaOComponenteFuncionar = this.ApplicationData.dadosGraficoRespostaNiveisDepressao;
          this.statisticsItems[0].porcentagem = this.calculoPorcentagemMinhaEmpresa(this.dadosParaOComponenteFuncionar) + "%";
          this.statisticsItems[0].porcentagem = this.dadosParaOComponenteFuncionar[0].percentual + "%";
          this.loadingShow = true;
        }
      },
      calculoPorcentagemMinhaEmpresa(dadosParaOComponenteFuncionar) {
        let calculoMinhaEmpresa = 0;
        let arrayExistencia = this.validarExistencia(
          dadosParaOComponenteFuncionar
        );
        if (arrayExistencia[0]) {
          calculoMinhaEmpresa = this.calculoFormula(
            dadosParaOComponenteFuncionar,
            arrayExistencia[1]
          );
        }
        return calculoMinhaEmpresa.toString().replace(".", ",");
      },
      validarExistencia(dadosParaOComponenteFuncionar) {
        let validador = false;
        let totalizadorAlto = 0;
        for (const element of dadosParaOComponenteFuncionar) {
          if (
            element["escala_depresao"] == "alta" &&
            element["totalizador"] > 0
          ) {
            validador = true;
            totalizadorAlto = element["totalizador"] * 100;
          }
        }
        return [validador, totalizadorAlto];
      },
      calculoFormula(dadosParaOComponenteFuncionar, valorDivisao) {
        let sum = 0;
        for (const element of dadosParaOComponenteFuncionar) {
          sum += parseFloat(element["totalizador"]);
        }
        return (valorDivisao / sum).toFixed(1);
      },
    },
    watch: {
        ApplicationData: function () {
        this.buscaDadosCardEstatisticasDepressao();
      },
    },
  };
  </script>
  <style scoped>
  .height-custom{
    height: 160px;
  }

  .img-fluid{
    width: 48px;
    height: 48px;
  }

  .porcentagemEstatisticas{
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
  }

  .centralizar_icone_porcentagem{
    display: flex;
    justify-content: center;
    align-items: center
  }

  .titulo_espacamento{
    height: 70px;
    text-align:center;
    padding-top: 5px;
  }
  
  .tituloMinhaEmpresa{
    padding-top: 25px;
  }

  @media (min-width:2000px) {
    .titulo_tamanho_responsivo{
      font-size: 1.6rem !important ;
    }
    .img-fluid{
      width: 60px;
      height: 60px;
    }
    .porcentagemEstatisticas{
      font-size: 24px;
    }
    .centralizar_icone_porcentagem{
      margin-top:5px;
    }
  }
  @media (min-width: 1920px){
    .titulo_tamanho_responsivo{
      font-size: 1.2rem;
    }
  }
  @media (max-width: 1600px) {
    .titulo_espacamento{
      height: 80px;
    }
    .centralizar_icone_porcentagem{
      margin-top:5px;
    }
  }
  @media (min-width: 1200px) and (max-width: 1440px) {
    .col-xl-3{
      flex:0 0 50%;
      max-width:50%;
    }
    .porcentagemEstatisticas{
      font-size: 18px;
    }
    .titulo_espacamento{
      height: 50px;
    }
    .titulo_tamanho_responsivo{
      font-size: 12px;
    }
    .tituloMinhaEmpresa{
      padding-top: 5px;
    }
    .centralizar_icone_porcentagem{
      margin-top:-5px;
    }
  }
  @media (max-width: 1199px) {
    .centralizar_icone_porcentagem{
      margin-top:-15px;
    }
  }
  @media (max-width: 991px) {
    .centralizar_icone_porcentagem{
      margin-top:-25px;
    }
  }
  </style>
