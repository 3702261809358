<template>
  <div>
    <div v-if="ApplicationData.dadosGraficoRespostaIndicacao.length > 0">
      <b-overlay :show="!loadingShow" rounded="lg" opacity="0.6">
        <b-card-body class="justify-content-center mb-n1 mt-n2">
          <b-row class="mt-2 espacamento">
            <b-col lg="12" md="12">
              <div class="d-flex justify-content-center flex-column align-items-center">
                <div class="d-flex justify-content-center align-items-center">
                  <img :src="mybusinessstar" class="imagem" alt="Indicacao de Resposta">
                  <span class="percentual"><strong>{{ media_indicacao }}</strong></span>
                </div>
                <div class="d-flex justify-content-center col-12 mt-1">
                  <span class="texto text-center">MÉDIA DAS NOTAS DA APLICAÇÃO</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-overlay>
    </div>
    <div
      v-else
      class="height-custom d-flex align-items-center justify-content-center"
    >
        <card-sem-dados :mensagemTipo="validarCardSemDados.tipagemErro"/>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BOverlay,
  BCardTitle,
} from "bootstrap-vue";

import mybusinessstar from "@/assets/custom-icons/mybusinessstar.svg";
import CardSemDados from "./CardSemDados.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BOverlay,
    BCardTitle,
    CardSemDados,
  },
  setup() {
    return {};
  },
  props: {
    ApplicationData: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
  },
  data() {
    return {
      loadingShow: false,
      mybusinessstar: mybusinessstar,
      media_indicacao: 0,
      validarCardSemDados: {
        verificadorVazio: true,
        tipagemErro:2,
      },
    };
  },
  beforeMount() {
    this.buscaDadosRespostasIndicacao();
  },
  methods: {
    buscaDadosRespostasIndicacao() {
      this.loadingShow = false;
      if (this.ApplicationData.dadosGraficoRespostaIndicacao.length > 0) {
        this.media_indicacao = this.ApplicationData.dadosGraficoRespostaIndicacao[0].media_indicacao;
        this.loadingShow = true;
      } else {
        this.validarCardSemDados.verificadorVazio = true;
        this.validarCardSemDados.tipagemErro = 2;
      }
    },
  },
  watch: {
    ApplicationData: function () {
      this.buscaDadosRespostasIndicacao();
    },
  },
};
</script>

<style scoped>
.height-custom{
  height: 180px;
}
.texto {
  color: #B9B9C3 !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  font-family: 'Montserrat', sans-serif;
}

.titulo {
  font-size: 18px;
  color: #5E5873;
  line-height: 21.94px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}

.alturaCard {
  height: 229.7px;
}

.percentual {
  margin-left: 5px;
  font-style: Montserrat;
  font-weight: 600;
  font-size: 18px;
  color: #5E5873;
}

@media (min-width: 1920px) {
  .percentual {
    font-size: 2rem;
  }
  .imagem {
    width: 80px;
    height: 80px;
  }
  .texto {
    font-size: 1rem;
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .alturaCard {
    height: 244.7px;
  }
}

@media (min-width: 3000px) {
  .titulo {
    font-size: 2.3rem !important;
  }
}

@media (min-width: 2000px) {
  .texto {
    font-size: 1.2rem !important;
  }
  .alturaCard {
    height: 245.7px;
  }
}

@media (min-width: 1400px) and (max-width: 1500px) {
  .alturaCard {
    height: 246.7px;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .alturaCard {
    height: 309.7px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .espacamento {
    margin-top: 55px !important;
  }
  .texto {
    font-size: 1.2rem !important;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .alturaCard {
    height: 349.7px;
  }
}

@media (min-width: 1200px) and (max-width: 1200px) {
  .alturaCard {
    height: 311.7px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .alturaCard {
    height: 225.7px !important;
  }
}

.card-statistics {
  padding: 15px;
}

@media (max-width: 767px) {
  .card-statistics {
    padding: 10px;
  }
  .texto {
    font-size: 0.8rem;
  }
  .percentual {
    font-size: 1.5rem;
  }
  .imagem {
    width: 60px;
    height: 60px;
  }
}
</style>
